import {includes, isString, map} from 'lodash'
import {NavLink} from 'react-router-dom'
import {KTSVG} from '../../../../helpers'
import {Spotlight} from './Spotlight'
import {routersApp} from './routers'
import {filterRoutersAppByModule} from '../../../../utils'
import {useAuth} from '../../../../../app/modules/auth'

const HorizontalMenu = () => {
  const {currentUser} = useAuth()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semi py-4 fs-6 left '
      style={{position: 'fixed', left: '75px'}}
      data-kt-menu='true'
    >
      <div className='d-flex mh-110px  ' style={{marginLeft: '100px'}}>
        <div
          id='total-button'
          className='w-120px mw-120px h-100px me-8 rounded-3 d-flex align-items-center justify-content-center cursor-pointer bg-hover-light-primary'
          data-kt-menu-dismiss='true'
        >
          <Spotlight />
        </div>
        <div className='d-flex '>
          {map(filterRoutersAppByModule(routersApp, currentUser?.modules), (router) => {
            const isExternalLink = router?.path?.startsWith('https')
            if (isExternalLink) {
              return (
                <div
                  key={router.id}
                  className={`w-120px mw-120px h-110px me-8  rounded-3 d-flex  align-items-center justify-content-center bg-hover-light-primary ${
                    includes([3, 5], router?.id) ? 'pt-3' : ''
                  }`}
                  data-kt-menu-dismiss='true'
                >
                  <a
                    href={router.path || ''}
                    className='d-flex flex-column justify-content-center align-items-center '
                    target='_blank'
                    rel='noreferrer'
                  >
                    {isString(router.icon) && <KTSVG path={router.icon} className='svg-icon-3x' />}
                    <span className='mt-3 fs-7 fs-normal  text-center' style={{color: '#22313e'}}>
                      {router.title}
                    </span>
                  </a>
                </div>
              )
            }

            return (
              <div
                key={router.id}
                className={`w-120px mw-120px h-110px me-8  rounded-3 d-flex  align-items-center justify-content-center bg-hover-light-primary ${
                  includes([3, 5], router?.id) ? 'pt-3' : ''
                }`}
                data-kt-menu-dismiss='true'
              >
                <NavLink
                  to={router.path || ''}
                  className='d-flex flex-column justify-content-center align-items-center '
                >
                  {isString(router.icon) && <KTSVG path={router.icon} className='svg-icon-3x' />}
                  <span className='mt-3 fs-7 fs-normal  text-center' style={{color: '#22313e'}}>
                    {router.title}
                  </span>
                </NavLink>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export {HorizontalMenu}
