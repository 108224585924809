const QUERIES = {
  CONFIG: 'config',
  CUSTOMERS_LIST: 'customers-list',
  DEMAND_LIST: 'demand-list',
  PROVINCE_LIST: 'province-list',
  DISTRICT_LIST: 'district-list',
  WARD_LIST: 'ward-list',
  STREET_LIST: 'street-list',
  COMMENT: 'comment',
}

export {QUERIES}
